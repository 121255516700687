<template>
  <div class="NewsDetail">
    <div class="cont pd40">
      <div class="container">
        <div class="head">{{newsDetail.title}}</div>
        <div class="date">{{newsDetail.updated_at}}</div>
        <el-divider></el-divider>
        <div class="__cont__"
             v-html="newsDetail.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosApi from "../api/api.js";
export default {
  data () {
    return {
      newsDetail: {
        newsDetail: '',
        content: '',
        updated_at: '',
        next: '',
        prev: '',
      }
    }
  },
  beforeMount () {
    if (this.$route.params.id != 'index') {
      axiosApi(
        'newsinfo/' + this.$route.params.id, {}, 'get'
      ).then((res) => {
        this.newsDetail = res.data
      })
    } else {
      axiosApi(
        'news/20', {}, 'get'
      ).then((res) => {
        console.log(res.data.data[0].id)
        return axiosApi(
          'newsinfo/' + res.data.data[0].id, {}, 'get'
        )
      }).then(res => {
        this.newsDetail = res.data
      })
    }
  },
  methods () {

  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/banner.png) center center no-repeat;
  height: 300px;
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.cont {
  .head {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }
  .date {
    font-size: 14px;
    color: #545454;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  ._navss {
    display: flex;
    justify-content: center;
    a {
      width: 40%;
      display: block;
      line-height: 1;
      transition: ease 0.5s;
      margin-bottom: 10px;
      padding: 10px;
      span {
        display: block;
        margin-bottom: 10px;
        color: #ffc107;
        font-weight: bold;
      }
      &:nth-child(1) {
        text-align: right;
        border-right: 1px solid #ffc107;
      }
      &:hover {
        color: #ffc107;
      }
    }
  }
}
</style>

<style lang="scss">
.__cont__ {
  margin-top: 30px;
  img {
    max-width: 100%;
  }
}
</style>